import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import _ from "lodash";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";

import { prevPageUrl, nextPageUrl } from "../utils/common";
import { Layout } from "../components";
import { PrimarySection } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Index page (/pages/:page)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */

const Index = ({ data, location, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const posts = data.allGhostPost.edges;

  const isFirst = currentPage === 2;
  const isLast = currentPage === numPages + 1;
  const baseUrl = `/pages`;
  const prevPage = prevPageUrl(baseUrl, currentPage);
  const nextPage = nextPageUrl(baseUrl, currentPage);

  return (
    <>
      <MetaData data={data} location={location} type="website" />
      <Layout>
        <div className="max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-8">
          <h1 className="uppercase">Latest</h1>
          {_.chunk(posts, 7).map((posts, i) => (
            <PrimarySection key={`posts-${i}`} posts={posts} isHome={false} />
          ))}
          <nav className="px-4 flex items-center justify-between sm:px-0">
            {!isFirst && (
              <div className="-mt-px w-0 flex-1 flex">
                <Link
                  to={prevPage}
                  className="pt-4 pr-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700"
                >
                  <ArrowNarrowLeftIcon
                    className="mr-3 h-5 w-5"
                    aria-hidden="true"
                  />
                  Previous
                </Link>
              </div>
            )}
            {!isLast && (
              <div className="-mt-px w-0 flex-1 flex justify-end">
                <Link
                  to={nextPage}
                  className="pt-4 pl-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700"
                >
                  Next
                  <ArrowNarrowRightIcon
                    className="ml-3 h-5 w-5"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            )}
          </nav>
        </div>
      </Layout>
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Index;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
